import { gql } from '@apollo/client';
import MoneyFields from '../money/money-fields';

const NoteFields = gql`
  fragment NoteFields on InvoiceType {
    id
    folio
    amountWithIva {
      ...MoneyFields
    }
  }
  ${MoneyFields}
`;

export default NoteFields;
