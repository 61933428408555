import { gql } from '@apollo/client';

const CompanyEvaluationRestrictionMinimalFields = gql`
  fragment CompanyEvaluationRestrictionMinimalFields on CompanyEvaluationRestrictionsType {
    id
    status
    restrictionType
    restriction
    restrictionLabel
    statusLabel
  }
`;

export default CompanyEvaluationRestrictionMinimalFields;
