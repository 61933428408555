import { gql } from '@apollo/client';

const ExecutiveFields = gql`
  fragment ExecutiveFields on UserType {
    id
    firstName
    lastName
    completeName @client
    email
    phoneNumber
  }
`;

export default ExecutiveFields;
