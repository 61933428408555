import { gql } from '@apollo/client';

const OfferMinimalFields = gql`
  fragment OfferMinimalFields on OfferType {
    id
    monthlyRate
    defaultRate
    transferCondition
  }
`;

export default OfferMinimalFields;
