import { gql } from '@apollo/client';

const MasterEntityMinimalFields = gql`
  fragment MasterEntityMinimalFields on MasterEntityType {
    id
    name
    displayNationalIdentifier
  }
`;

export default MasterEntityMinimalFields;
