import { gql } from '@apollo/client';

const InvoiceDteTypeMinimalFields = gql`
  fragment InvoiceDteTypeMinimalFields on InvoiceDteTypeType {
    id
    name
  }
`;

export default InvoiceDteTypeMinimalFields;
