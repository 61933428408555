export const sharedInvoiceInputFields = `
    $distinct: Boolean,
    $allIssuedCompany: Boolean,
    $allReceiverCompany: Boolean,
    $availableForFinancing: String,
    $hasActivePreoffer: Boolean,
    $hasValidPreoffer: Boolean,
    $hasActiveOperation: Boolean,
    $hasEvaluatingPreoffer: Boolean,
    $hasRejectedPreoffer: Boolean,
    $company_MasterEntity_Name_Icontains: String,
    $company_MasterEntity_Name_Iexact: String,
    $company_MasterEntity_Name_Istartswith: String,
    $preoffer_Preofferevaluationrequest_Status: String,
    $preoffer_Preofferevaluationrequest_RequestingPlatform_Code: String,
    $preoffer_Preofferevaluationrequest_Status_In: [String],
    $preoffer_Preofferevaluationrequest_Isnull: Boolean,
    $ratificationmanager_Actions_Isnull: Boolean,
    $ratificationmanager_RatificationPriority_Value_In: [Int],
    $collectionManagerRelation_Actions_Isnull: Boolean,
    $companyId: Int,
    $companyId_In: [ID],
    $currency: String,
    $creditNoteStatus: [String],
    $dateIssued: Date,
    $dateIssued_Gt: Date,
    $dateIssued_Gte: Date,
    $dateIssued_Lt: Date,
    $dateIssued_Lte: Date,
    $dateExpiration: Date,
    $dateExpiration_Gt: Date,
    $dateExpiration_Gte: Date,
    $dateExpiration_Lt: Date,
    $dateExpiration_Lte: Date,
    $dateToPay: Date,
    $dateToPay_Gt: Date,
    $dateToPay_Gte: Date,
    $dateToPay_Lt: Date,
    $dateToPay_Lte: Date,
    $dfstateIn: [String],
    $documentfinancestate_PaymentDate_Gte: Date,
    $documentfinancestate_PaymentDate_Lte: Date,
    $dteType_Code_In: [String],
    $dteType_Code: String,
    $dteType_Country_Name: String,
    $dteType_CountryId: Int,
    $first: Int,
    $folio: String,
    $globalFilter: String = "",
    $hasBeenCeded: Boolean,
    $hasNotOffer: Boolean,
    $hasOffer: Boolean,
    $hasPreoffer: Boolean,
    $hasValidOffer: Boolean,
    $id_In: [String],
    $loanedStatus: [String],
    $offset: Int,
    $orderBy: String = "-dateIssued",
    $receiverId: Int,
    $receiver_In: [ID],
    $receiver_Name_Icontains: String,
    $receiver_Name_Iexact: String,
    $receiver_Name_Istartswith: String,
    $receiver_Rut_In: [String],
    $receiver_Rut: String,
    $receptionDate_Gt: DateTime,
    $receptionDate_Gte: DateTime,
    $receptionDate_Lt: DateTime,
    $receptionDate_Lte: DateTime,
    $receptionDate: DateTime,
    $siiStatus: [String],
    $status: [String],
    $relatedConfirming_Status_In: [String],
    $relatedConfirming_InvoiceMoneyTransfer_Status: String,
    $assignmentSet_Status: String,
    $relatedConfirming_Id_Isnull: Boolean
    $inCollection: Int,
    $hasPendingCollection: Boolean
    $historicCollection: Int,
    $availableForOrdering: Boolean = false,
    $availableForTransfer: Boolean = false,
    $availableForReintegration: Boolean = false,
    $availableForReplacement: Boolean = false,
    $recommended: Boolean = false,
    $ratificationStatus: String,
    $validDteType: Boolean,
    $filterByLightningPaymentAuthorized: Boolean,
    $validSiiCredendials: Boolean,
    $collectionDate: Date,
    $hasPendingSignature: Boolean = false,
    $filterByRatificator: Boolean = false,
    $debtorId: String,
    $operation_RequestingPlatform_Code: String,
    $collectionManagerRelation_Status: String,
    $filterByPendingRestrictions: Boolean,
    $filterByRequestingPlatformOrAssigned: String,
    $companyExecutiveAssignedId: ID,    
    $farmingByCommercialType: String,
    $masterEntitySource: String,
`;

export const sharedInvoiceInput = `
    distinct: $distinct,
    allIssuedCompany: $allIssuedCompany,
    allReceiverCompany: $allReceiverCompany,
    hasNotOffer: $hasNotOffer,
    hasOffer: $hasOffer,
    hasPreoffer: $hasPreoffer,
    availableForFinancing: $availableForFinancing,
    hasActivePreoffer: $hasActivePreoffer,
    hasValidPreoffer: $hasValidPreoffer,
    hasActiveOperation: $hasActiveOperation,
    hasEvaluatingPreoffer: $hasEvaluatingPreoffer,
    hasRejectedPreoffer: $hasRejectedPreoffer,
    company_MasterEntity_Name_Icontains: $company_MasterEntity_Name_Icontains, 
    company_MasterEntity_Name_Iexact: $company_MasterEntity_Name_Iexact, 
    company_MasterEntity_Name_Istartswith: $company_MasterEntity_Name_Istartswith, 
    companyId: $companyId,
    companyId_In: $companyId_In,
    creditNoteStatus: $creditNoteStatus,
    currency: $currency,
    status: $status,
    dateIssued_Gt: $dateIssued_Gt,
    dateIssued_Gte: $dateIssued_Gte,
    dateIssued_Lt: $dateIssued_Lt,
    dateIssued_Lte: $dateIssued_Lte,
    dateIssued: $dateIssued,
    dateExpiration_Gt: $dateExpiration_Gt,
    dateExpiration_Gte: $dateExpiration_Gte,
    dateExpiration_Lt: $dateExpiration_Lt,
    dateExpiration_Lte: $dateExpiration_Lte,
    dateExpiration: $dateExpiration,
    dateToPay_Gt: $dateToPay_Gt,
    dateToPay_Gte: $dateToPay_Gte,
    dateToPay_Lt: $dateToPay_Lt,
    dateToPay_Lte: $dateToPay_Lte,
    dateToPay: $dateToPay,
    dfstateIn: $dfstateIn,
    documentfinancestate_PaymentDate_Gte: $documentfinancestate_PaymentDate_Gte,
    documentfinancestate_PaymentDate_Lte: $documentfinancestate_PaymentDate_Lte,
    dteType_Code_In: $dteType_Code_In,
    dteType_Code: $dteType_Code,
    dteType_Country_Name: $dteType_Country_Name,
    dteType_CountryId: $dteType_CountryId,
    first: $first,
    folio: $folio,
    globalFilter: $globalFilter,
    hasBeenCeded: $hasBeenCeded,
    hasValidOffer: $hasValidOffer,
    id_In: $id_In,
    loanedStatus: $loanedStatus,
    offset: $offset,
    orderBy: $orderBy,
    receiver_Name_Icontains: $receiver_Name_Icontains, 
    receiver_Name_Iexact: $receiver_Name_Iexact, 
    receiver_Name_Istartswith: $receiver_Name_Istartswith, 
    receiverId: $receiverId,
    receiver_In: $receiver_In,
    receiver_Rut_In: $receiver_Rut_In,
    receiver_Rut: $receiver_Rut,
    receptionDate_Gt: $receptionDate_Gt,
    receptionDate_Gte: $receptionDate_Gte,
    receptionDate_Lt: $receptionDate_Lt,
    receptionDate_Lte: $receptionDate_Lte,
    receptionDate: $receptionDate,
    siiStatus: $siiStatus,
    relatedConfirming_Status_In: $relatedConfirming_Status_In,
    relatedConfirming_InvoiceMoneyTransfer_Status: $relatedConfirming_InvoiceMoneyTransfer_Status,
    assignmentSet_Status: $assignmentSet_Status,
    relatedConfirming_Id_Isnull: $relatedConfirming_Id_Isnull,
    inCollection: $inCollection,
    hasPendingCollection: $hasPendingCollection
    historicCollection: $historicCollection,
    availableForOrdering: $availableForOrdering,
    availableForTransfer: $availableForTransfer,
    availableForReintegration: $availableForReintegration,
    availableForReplacement: $availableForReplacement,
    recommended: $recommended,
    ratificationStatus: $ratificationStatus,
    validDteType: $validDteType,
    filterByLightningPaymentAuthorized: $filterByLightningPaymentAuthorized,
    validSiiCredendials: $validSiiCredendials,
    collectionDate: $collectionDate,
    preoffer_Preofferevaluationrequest_Status: $preoffer_Preofferevaluationrequest_Status,
    preoffer_Preofferevaluationrequest_Status_In: $preoffer_Preofferevaluationrequest_Status_In,
    preoffer_Preofferevaluationrequest_Isnull: $preoffer_Preofferevaluationrequest_Isnull,
    preoffer_Preofferevaluationrequest_RequestingPlatform_Code: $preoffer_Preofferevaluationrequest_RequestingPlatform_Code,
    ratificationmanager_Actions_Isnull: $ratificationmanager_Actions_Isnull,
    ratificationmanager_RatificationPriority_Value_In: $ratificationmanager_RatificationPriority_Value_In,
    collectionManagerRelation_Actions_Isnull: $collectionManagerRelation_Actions_Isnull,
    hasPendingSignature: $hasPendingSignature
    filterByRatificator: $filterByRatificator
    debtorId: $debtorId
    operation_RequestingPlatform_Code: $operation_RequestingPlatform_Code
    collectionManagerRelation_Status: $collectionManagerRelation_Status
    filterByPendingRestrictions: $filterByPendingRestrictions,
    filterByRequestingPlatformOrAssigned: $filterByRequestingPlatformOrAssigned,
    farmingByCommercialType: $farmingByCommercialType,
    company_ExecutiveAssignedId: $companyExecutiveAssignedId,
    company_MasterEntity_Source_Name: $masterEntitySource
`;
