import { gql } from '@apollo/client';

const CollectionActionMinimalFields = gql`
  fragment CollectionActionMinimalFields on CollectionActionType {
    id
    actionType
    createdAt
  }
`;

export default CollectionActionMinimalFields;
