import { gql } from '@apollo/client';
import MoneyFields from '../money/money-fields';

const ReferenceFields = gql`
  fragment ReferenceFields on InvoiceType {
    id
    folio
    amountWithIva {
      ...MoneyFields
    }
    dteType {
      id
      code
    }
  }
  ${MoneyFields}
`;

export default ReferenceFields;
